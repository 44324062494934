import { render, staticRenderFns } from "./PrecoRevista.vue?vue&type=template&id=879b1292&scoped=true&lang=pug&"
import script from "./PrecoRevista.vue?vue&type=script&lang=js&"
export * from "./PrecoRevista.vue?vue&type=script&lang=js&"
import style0 from "./PrecoRevista.styl?vue&type=style&index=0&id=879b1292&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "879b1292",
  null
  
)

export default component.exports